import React from "react";

interface CheckbuttonProps {
	p_id: string;
	p_class: string;
	p_icon: string;
	p_change: (e: React.ChangeEvent<HTMLInputElement>) => void;
	p_ref?: React.RefObject<HTMLInputElement>;
}

function Checkbutton({ p_id, p_class, p_icon, p_change, p_ref}: CheckbuttonProps): React.ReactElement {
	const uid = `${p_class}-${p_id}`;

	return (
		<div className={p_class}>
			<input
				id={ uid }
				className={ `cb-${p_class}` }
				type="checkbox"
				style={{ display: 'none' }}
				onChange={ p_change }
				ref={p_ref}
			/>
			<label htmlFor={ uid } className={ `l-${ p_class }` }>
				<span className={ `material-symbols-outlined icon-${ p_class }` }>{ p_icon }</span>
			</label>
		</div>
	);
}

export default Checkbutton;
