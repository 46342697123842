import React from 'react';

interface ShortcutPanelProps {
	p_shortcuts: { [key:string]: string };
	p_shortcuts_set: React.Dispatch<React.SetStateAction<{ [key:string]: string }>>;
	p_edit: boolean;
}

function fn_Register(
    event: React.KeyboardEvent<HTMLTextAreaElement>,
    p_shortcuts_set: React.Dispatch<React.SetStateAction<{ [key: string]: string }>>,
    button: string
) {
    if (event.code === 'Backspace') {
        event.preventDefault();
        p_shortcuts_set((copy) => ({ ...copy, [button]: '' }));
        return;
    }

    const mod = ['Control', 'Meta', 'Alt', 'Shift'];
    if (mod.includes(event.key)) {
        event.preventDefault();
        return;
    }

    const mod_keys = ['ctrlKey', 'metaKey', 'altKey', 'shiftKey']
        .filter((modifier) => event[modifier as keyof typeof event])
        .map((modifier) => {
            const mod = modifier.replace('Key', '');
            return mod.charAt(0).toUpperCase() + mod.slice(1).toLowerCase();
        });

    if (mod_keys.length === 0) return;

    event.preventDefault();
    const shortcut = [...mod_keys, event.code].join('+');
    p_shortcuts_set((copy) => ({ ...copy, [button]: shortcut }));
}

function ShortcutPanel({p_shortcuts, p_shortcuts_set}: ShortcutPanelProps): React.ReactElement {

	const shortcutItems = [
		{ key: 'add', icon: 'add', placeholder: 'New' },
		{ key: 'select', icon: 'text_select_start', placeholder: 'Select' },
		{ key: 'edit', icon: 'edit_note', placeholder: 'Shortcuts' },
		{ key: 'clip', icon: 'content_paste', placeholder: 'Clip merged' },
		{ key: 'clear', icon: 'scan_delete', placeholder: 'Clear all' },
	];

	return (
		<div className='shortcut-panel' onKeyDown={(e) => e.stopPropagation()} tabIndex={-1} ref={(el) => el?.focus()}>
			<div className='sc-buttons'>
				{shortcutItems.map(({ key, icon, placeholder }) => (
					<div className='sc' key={key}>
						<textarea className='sc-shortcut' rows={1} spellCheck={false} value={p_shortcuts[key]} onKeyDown={(e) => fn_Register(e, p_shortcuts_set, key)} />
						<label className='sc-icon'> <span className={`material-symbols-outlined`}> {icon} </span></label>
						<textarea className='sc-name' rows={1} cols={10} disabled={true} placeholder={placeholder} />
					</div>
				))}
			</div>
		</div>
	);
}

export default ShortcutPanel;
