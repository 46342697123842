import React from 'react';
import Checkbutton from 'comps/Checkbutton';
const s = 'material-symbols-outlined icon-';

interface TextcardProps {
	id: string;
	p_tokens: number;
	p_ta_ref: React.RefObject<HTMLTextAreaElement>;
	p_select: boolean;
	p_fetch: (id: string) => void;
	p_delete: (id: string) => void;
}


function Textcard({ id, p_delete, p_ta_ref, p_tokens, p_fetch, p_select}: TextcardProps): React.ReactElement {
	const [__cb_expand, __cb_expand_set] = React.useState(false);
	React.useEffect(() => {
		if (p_select && p_ta_ref.current === document.activeElement) {
			p_ta_ref.current?.select();
		}
	}, [p_select, p_ta_ref]);
	

	function fn_Resize(p_ta_ref: React.RefObject<HTMLTextAreaElement>, t: boolean) {
		const ta = p_ta_ref.current;
		if (!ta) return;
		ta.style.height = 'auto';
		if (t) {
			ta.style.height = ta.scrollHeight+'px';
			if (ta.scrollHeight > ta.clientHeight) {
				ta.style.overflow = 'auto';
				ta.style.whiteSpace = 'nowrap';
			}
			else {
				ta.style.overflow = 'hidden';
				ta.style.whiteSpace = 'normal';
			}
		}
		else {
			ta.style.overflow = 'auto';
			ta.style.whiteSpace = 'normal';
		}
	}

	function fn_Input() {
		if (__cb_expand) fn_Resize(p_ta_ref, true);
		if (p_ta_ref.current) p_fetch(id);

	}

	function fn_Spellcheck(on: boolean) {
		if (p_ta_ref.current) p_ta_ref.current.spellcheck = on;
	}

	function fn_Tab (e: React.KeyboardEvent<HTMLTextAreaElement>) {
		if (e.key === 'Tab') {
			e.preventDefault();
			const tab = "\t";
			const start = p_ta_ref.current?.selectionStart || 0;
			const end = p_ta_ref.current?.selectionEnd || 0;
			const value = p_ta_ref.current?.value || "";
			p_ta_ref.current!.value = value.substring(0, start) + tab + value.substring(end);
			p_ta_ref.current!.selectionStart = p_ta_ref.current!.selectionEnd = start + tab.length;
		}
	}

	function fn_Cb_Expand (e: React.ChangeEvent<HTMLInputElement>){
		__cb_expand_set(e.target.checked);
		fn_Resize(p_ta_ref, e.target.checked);
	}

	function fn_Select (){
		if (p_ta_ref.current && p_ta_ref.current.selectionStart === p_ta_ref.current.selectionEnd) {
			p_ta_ref.current.select();
		}
	}

	return (
		<div className='tc' id={id}>
			<div className='u'>
				<div className='u-l'>
					<Checkbutton 
						p_class='expand'
						p_id={ id }
						p_icon='chevron_right'
						p_change={(e)=>fn_Cb_Expand(e)}
					/>
					<button className='delete' onClick={() => p_delete(id)}> <span className={`${s}delete`}>close</span> </button>
				</div>
				<label className='tokens tc-tokens'>{p_tokens}</label>
			</div>
			
			<textarea ref={p_ta_ref}
				className='ta' 
				onInput={fn_Input} 
				onFocus={()=>{fn_Spellcheck(true); if(p_select)fn_Select()}}
				onBlur={()=>fn_Spellcheck(false)}
				onKeyDown={(e)=>fn_Tab(e)}
			></textarea>
		</div>
	);
}

export default Textcard;
