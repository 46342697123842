import React from 'react';
import Textcard from 'comps/Textcard';
import Checkbutton from 'comps/Checkbutton';
import ShortcutPanel from 'comps/ShortcutPanel';
const m = 'material-symbols-outlined icon-';


function App() {
	const [__tcs, __tcs_set] = React.useState<string[]>(['1']);
	const [__idnext, __idnext_set] = React.useState<number>(2);
	const [__tokens, __tokens_set] = React.useState<{ [id: string]: number }>({ '1': 0 });
	const [__sum, __sum_set] = React.useState<number>(0);
	const [__select, __select_set] = React.useState<boolean>(false);
	const [__shortcuts, __shortcuts_set] = React.useState<{ [key: string]: string }>(() => {
		const savedShortcuts = localStorage.getItem('shortcuts');
		return savedShortcuts ? JSON.parse(savedShortcuts) : {};
	});	
	const [__edit, __edit_set] = React.useState<boolean>(false);
	const _textRefs = React.useRef<{ [id: string]: React.RefObject<HTMLTextAreaElement> }>({ '1': React.createRef() });
	const [_width, __width_set] = React.useState<string>('100%');


	const _add_ref = React.useRef<HTMLButtonElement>(null);
	const _select_ref = React.useRef<HTMLInputElement>(null);
	const _edit_ref = React.useRef<HTMLInputElement>(null);
	const _clip_ref = React.useRef<HTMLButtonElement>(null);
	const _clear_ref = React.useRef<HTMLButtonElement>(null);
	

	function fn_Update(n: { [id: string]: number }) {
		__tokens_set(n);
		const nsum = Object.values(n).reduce((a, b) => a + b, 0);
		__sum_set(nsum);
	}

	async function fn_Fetch(id:string) {
		// get text from textarea
		const ta = _textRefs.current[id].current;
		const text = ta?.value;
	
		// fetch token count
		const response = await fetch('https://gpt-4mat.com/count_tokens', {
			method: 'POST',
			headers: { 'Content-Type': 'application/json', },
			body: JSON.stringify({ text }),
		});
		const fetched = await response.json();
		const count = fetched.num_tokens;
	
		// store token count
		fn_Update({ ...__tokens, [id]: count});
	}	

	const fn_Add = React.useCallback(() => {
		const idnew = __idnext.toString();
		__tcs_set([...__tcs, idnew]);
		_textRefs.current[idnew] = React.createRef();
		__idnext_set(__idnext + 1);
		fn_Update(({ ...__tokens, [idnew]: 0 }));
	}, [__idnext, __tcs, __tokens]);

	const fn_Clip = React.useCallback(async () => {
		const texts = __tcs
			.map(id => _textRefs.current[id].current?.value.trim())
			.filter(text => text !== "");
		const merged_text = texts.join('\n\n');

		try { await navigator.clipboard.writeText(merged_text); }
		catch (err) { console.error('Failed to copy merged text: ', err); }
	}, [__tcs]);

	function fn_Delete(id: string) {
		__tcs_set(__tcs.filter(tc => tc !== id));
		const n = { ...__tokens };
		delete n[id];
		fn_Update(n);
	}

	const fn_Clear = React.useCallback(() => {
		__tcs.forEach(id => {
			const textarea = _textRefs.current[id]?.current;
			if (textarea) { textarea.value = ''; }
		});

		const n = Object.keys(__tokens).reduce((acc, id) => ({ ...acc, [id]: 0 }), {});
		fn_Update(n);
	}, [__tcs, __tokens]);

	function fn_Cb_Select (e: React.ChangeEvent<HTMLInputElement>) {
		__select_set(e.target.checked);
	}

	const fn_Edit = React.useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
		__edit_set(e.target.checked);
	}, []);

	function fn_Width() { __width_set(_width === '100%' ? '800px' : '100%'); }



	React.useEffect(() => {
		const handleKeyPress = (e: KeyboardEvent) => {
			const k = [e.ctrlKey ? 'Control' : '', e.metaKey ? 'Meta' : '', e.altKey ? 'Alt' : '', e.shiftKey ? 'Shift' : '', e.code].filter(key => key !== '').join('+');
			
			if (k === __shortcuts['add']) { _add_ref.current?.click(); }
			if (k === __shortcuts['select']) { _select_ref.current?.click(); }
			if (k === __shortcuts['edit']) { _edit_ref.current?.click(); }
			if (k === __shortcuts['clip']) { _clip_ref.current?.click(); }
			if (k === __shortcuts['clear']) { _clear_ref.current?.click(); }

		};
		window.addEventListener('keydown', handleKeyPress);
		return () => { window.removeEventListener('keydown', handleKeyPress); };
	}, [__shortcuts, fn_Add, fn_Clear, fn_Clip, __select, __select_set, fn_Edit, __edit, __edit_set]);

	React.useEffect(() => {
		localStorage.setItem('shortcuts', JSON.stringify(__shortcuts));
	}, [__shortcuts]);



	return (
		<div className="App" style={{ width:_width }}>
			<div className='m'>
				<div className='m-l'>
					<button className='add' onClick={fn_Add} ref={_add_ref}> <span className={`${m}add`}>add</span> </button>
					<Checkbutton
						p_id='select'
						p_class='select'
						p_icon='text_select_start'
						p_change={(e) => fn_Cb_Select(e)}
						p_ref={_select_ref}
					/>
					<Checkbutton
						p_id='edit'
						p_class='edit'
						p_icon='edit_note'
						p_change={(e)=>fn_Edit(e)}
						p_ref={_edit_ref}
					/>
					<button className='clip' onClick={fn_Clip} ref={_clip_ref}> <span className={`${m}clip`}>content_paste</span> </button>
				</div>
				<div className='m-r'>
					<label className='tokens m-tokens'>{__sum}</label>
					<button className='clear' onClick={fn_Clear} ref={_clear_ref}> <span className={`${m}clear`}>scan_delete</span> </button>
				</div>
			</div>
			<div className='tcs' style={{ display: 'flex', flexDirection: 'column' }}>
				{__tcs.map(id => <Textcard 
					key={id} 
					id={id} 
					p_delete={fn_Delete} 
					p_ta_ref={_textRefs.current[id]}
					p_tokens={__tokens[id]} 
					p_select={__select}
					p_fetch={fn_Fetch}
				/>)}
			</div>
			{__edit && (
				<ShortcutPanel
					p_shortcuts={__shortcuts}
					p_shortcuts_set={__shortcuts_set}
					p_edit={__edit}
				/>
			)}
			<button className='width' onClick={fn_Width}> <span className={`${m}width`}>crop_free</span> </button>
		</div>
	);
}

export default App;
